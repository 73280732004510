import React, { useContext, useState } from 'react';
import chunk from 'lodash/chunk';

import ServerRenderContext from '../../new/context/serverRenderContext';
import ItemGalleryPage from '../../parts/ItemGalleryPage';

import Featured from './Featured';

type PLProps = {
  loadedBs: boolean;
};

function PostListing({ loadedBs }: PLProps) {
  const {
    state: {
      postListing: { featured, filtered },
    },
  } = useContext(ServerRenderContext);

  const [page, setPage] = useState(0);

  const safeSetPage = (page: number) => {
    setPage(page % pageCount);
  };

  const pages = chunk(filtered, 18);

  const pageCount = pages.length;

  return (
    <>
      {featured && <Featured loadedBs={loadedBs} item={featured} />}
      {pages && pages.length > 0 && (
        <ItemGalleryPage
          pageCount={pageCount}
          currentPage={page}
          setPage={safeSetPage}
          posts={(pages[page] || ([] as unknown)) as Item[]}
        />
      )}
    </>
  );
}

export default PostListing;
