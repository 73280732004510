import React, { useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import cx from 'classnames';

import ServerRenderContext from '../../new/context/serverRenderContext';

import follow from './follow';

type Category = {
  nav_text: string;
  slug: string;
  nav_children?: Category[];
};

type HeaderProps = {
  firstSlug?: string;
};

function Header({ firstSlug }: HeaderProps) {
  const {
    state: { nav, sellers },
  } = useContext(ServerRenderContext);

  const selectedCat = nav.find(
    (cat) => cat.slug === firstSlug || (cat.nav_children || []).find((nc) => nc.slug === firstSlug),
  );

  return (
    <div className="navbar navbar-inverse navbar-fixed-top">
      <div className="navbar-inner">
        <div className="container">
          <button
            type="button"
            className="btn btn-navbar"
            data-toggle="collapse"
            data-target=".nav-collapse"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="brand" href="/">
            PopOnIt.com
          </a>
          <div className="nav-collapse collapse">
            <ul className="nav">
              <li>
                <div className="btn-toolbar" style={{ margin: 0 }}>
                  {(nav as Category[]).map((top_cat) => (
                    <div className="btn-group" key={top_cat.slug}>
                      <a href={`/${top_cat.slug}`}>
                        <button
                          /* className="btn btn-inverse {% ifequal selected_top_nav top_cat.slug %}nav-btn-selected{% endifequal %}" */
                          className={cx('btn btn-inverse', {
                            'nav-btn-selected': top_cat.slug === (selectedCat && selectedCat.slug),
                          })}
                        >
                          {top_cat.nav_text}
                        </button>
                      </a>
                      {!isEmpty(top_cat.nav_children) && (
                        <>
                          <button
                            /* className="btn btn-inverse dropdown-toggle {% ifequal selected_top_nav top_cat.slug %}nav-btn-selected{% endifequal %}" */
                            className={cx('btn btn-inverse dropdown-toggle', {
                              'nav-btn-selected':
                                top_cat.slug === (selectedCat && selectedCat.slug),
                            })}
                            data-toggle="dropdown"
                          >
                            <span className="caret"></span>
                          </button>
                          <ul className="dropdown-menu">
                            {map(top_cat.nav_children, (child) => (
                              <li key={child.slug}>
                                <a href={`/${child.slug}`}>
                                  {child.slug === firstSlug ? (
                                    <strong>{child.nav_text}</strong>
                                  ) : (
                                    child.nav_text
                                  )}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </li>
              {/* <!-- add seller dropdown to end of nav --> */}
              <li className="dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="dropdown-toggle" id="drop5" role="button" data-toggle="dropdown">
                  By Seller <b className="caret"></b>
                </a>
                <ul id="menu3" className="dropdown-menu" role="menu" aria-labelledby="drop5">
                  {map(sellers, (seller) => (
                    <li role="presentation" key={seller.slug}>
                      <a role="menuitem" tabIndex={-1} href={`/${seller.slug}`}>
                        {seller.slug === firstSlug ? <strong>{seller.name}</strong> : seller.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <!-- add seller dropdown to end of nav --> */}
              <li className="dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="dropdown-toggle" id="drop6" role="button" data-toggle="dropdown">
                  Follow <b className="caret"></b>
                </a>
                <ul id="menu4" className="dropdown-menu" role="menu" aria-labelledby="drop6">
                  {map(follow, (site) => (
                    <li role="presentation" key={site.label}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        role="menuitem"
                        tabIndex={-1}
                        href={site.href}
                      >
                        {site.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              {/*
{#              <li>#}
{#                <form className="navbar-search pull-right">#}
{#                  <input type="text" className="search-query" placeholder="Search">#}
{#                </form>#}
{#              </li>#}
{#              <li><a className="pull-right" href="/submit" title="Submit an Item for Display on WantWant.co">Add Something</a></li> <!-- TODO: this should be a button -->#}
{#              <li><a className="pull-right" href="/about" title="About WantWant.co">About</a></li>#}
              <!-- <li><input type=search results=5 placeholder=Search... name=s /></li> -->
*/}
            </ul>
          </div>
          {/* <!--/.nav-collapse --> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
