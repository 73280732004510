import React, { useContext, useLayoutEffect } from 'react';
import cx from 'classnames';
import showdown from 'showdown';
import map from 'lodash/map';

import Meta from '../../components/Meta';

import { Thumbnail, Carousel } from './Carousel';
import Share from './Share';
import ServerRenderContext from '../../new/context/serverRenderContext';

const markdownConverter = new showdown.Converter();

type Props = {
  item: Item;
  minimal?: boolean;
  include_description?: boolean;
  loadedBs: boolean;
};

function ItemDetails(props: Props) {
  const { item, loadedBs } = props;

  useLayoutEffect(() => {
    if (!loadedBs) {
      return;
    }
    let timeout: any;
    // @ts-ignore
    window.jQuery(document).ready(function ($) {
      timeout = setTimeout(() => {
        // start hero block carousel
        $('.carousel').carousel({
          interval: 2000,
        });
        // add hide function to hero block
        $('#close-button-for-featured-item').on('click', function () {
          $('#featured-item-row').hide();
          $('#featured-item-horizontal-row-separator').hide();
        });
      }, 1000);
    });
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [item, loadedBs]);

  return (
    /*
        {% if add_goog_analytics %}
            {% include "part/google-analytics.html" %}
        {% endif %}
    */
    <>
      <div
        style={{ display: 'table' }}
        // itemscope
        // itemtype="http://schema.org/Product"
      >
        <DesktopMedia {...props} />
        <Info {...props} />
        <Meta {...props} />
      </div>
      <MobileMedia {...props} />
    </>
  );
}

function DesktopMedia(props: Props) {
  return <Media {...props} visibilityClass="hidden-phone" />;
}

function MobileMedia(props: Props) {
  return (
    <div className="visible-phone" style={{ paddingTop: 20, textAlign: 'center' }}>
      <div style={{ display: 'inline-block' }}>
        <Media {...props} disableTable />
      </div>
    </div>
  );
}

ItemDetails.defaultProps = {
  minimal: false,
  include_description: true,
};

export default ItemDetails;

type VisibilityClass = 'visible-phone' | 'hidden-phone';

type InfoProps = {
  item: Item;
  minimal?: boolean;
  include_description?: boolean;
  visibilityClass?: VisibilityClass;
};

function Info(props: InfoProps) {
  const { item, minimal, include_description, visibilityClass } = props;

  const {
    state: { cats, sellers },
  } = useContext(ServerRenderContext);

  const getSeller = (i: Item): string => {
    const label = i.seller && sellers[i.seller]?.name;
    return label || 'Seller';
  };

  return (
    <div
      style={{
        display: 'table-cell',
        verticalAlign: 'top',
        paddingLeft: 5,
      }}
      className={visibilityClass}
    >
      <div
        style={{
          verticalAlign: 'top',
        }}
        className={cx({
          'media-body': minimal,
        })}
      >
        {minimal ? (
          <>
            <h4
              // itemprop="name"
              className="media-heading"
            >
              {item.tag_line}
            </h4>
            <h5>{item.is_preorder ? `Preorder for ${item.release_date}` : item.tag_line2 || ''}</h5>
          </>
        ) : (
          <>
            <h3
            // itemprop="name"
            >
              {item.tag_line}
            </h3>
            <h4>{item.is_preorder ? `Preorder for ${item.release_date}` : item.tag_line2 || ''}</h4>
          </>
        )}
        {
          //   map(item.item_category_tags, (ct) => (
          //     <a key={ct.slug} href={`/${ct.slug}`}>
          //       <span className="label label-info">{ct.title}</span>
          //     </a>
          //   ))
          <div>
            {map(item.categories, (cat) => (
              <span key={cat}>
                <a href={`/${cat}`}>
                  <span className="label label-info">{cats[cat].title || cat}</span>
                </a>{' '}
              </span>
            ))}
          </div>
        }
        <p />
        {include_description && item.description && (
          <div dangerouslySetInnerHTML={{ __html: markdownConverter.makeHtml(item.description) }} />
        )}
      </div>
      <Share {...props} />
      <br />
      {/* TODO: track outbound clicks */}
      <a href={item.ext_url} rel="noopener">
        <button className="btn" style={{ verticalAlign: 'bottom' }}>
          Details
        </button>
      </a>{' '}
      <a
        // TODO
        // href="/out/{{ item.id }}/{{ item.slug }}"
        href={item.ext_url}
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <button className="btn btn-primary" style={{ verticalAlign: 'bottom' }}>
          {item.price ? `$${item.price} at` : ''} {getSeller(item)}
        </button>
      </a>
    </div>
  );
}

type MediaProps = {
  item: Item;
  minimal?: boolean;
  visibilityClass?: VisibilityClass;
  disableTable?: boolean;
};

function Media(props: MediaProps) {
  const { disableTable, minimal } = props;
  return (
    <div
      style={
        disableTable
          ? {}
          : {
              width: minimal ? 190 : 310,
              display: 'table-cell',
              paddingRight: 5,
            }
      }
      className={props.visibilityClass}
    >
      {minimal ? <Thumbnail {...props} /> : <Carousel {...props} />}
    </div>
  );
}
