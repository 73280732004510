import React from 'react';
import cx from 'classnames';
import isImage from 'is-image';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { getImage, getImageUrl } from '../../utils';

type Props = {
  item: Item;
  minimal?: boolean;
  include_description?: boolean;
};

export function Thumbnail(props: Props) {
  const { item } = props;
  let img = getImage(item);
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="pull-left thumbnail">
      <img
        // itemprop="image"
        className="media-object"
        data-src={img}
        src={img}
        width={185}
        height={185}
        alt={item.tag_line}
      />
    </a>
  );
}

export function Carousel(props: Props) {
  const { item } = props;
  const id = `hero_carousel_${item.slug}`;
  return isEmpty(item.tooltipimages) ? (
    <div className="thumbnail">
      <img
        // itemprop="image"
        src={getImage(item)}
        width="300"
        height="300"
        alt={item.tag_line}
      />
    </div>
  ) : (
    <div id={id} className="carousel slide">
      <ol className="carousel-indicators">
        {map(item.tooltipimages, (i, idx) => (
          <li
            key={i}
            data-target={id}
            data-slide-to={`${idx}`}
            // className="{% if forloop.first %}active{% endif %}"
            className={cx({
              active: idx === 0,
            })}
          />
        ))}
      </ol>
      <div className="carousel-inner">
        {map(item.tooltipimages, (i, idx) => (
          <div
            /* className="item{% if forloop.first %} active{% endif %}" */
            className={cx('item', {
              active: idx === 0,
            })}
            key={i}
          >
            {isImage(i) ? (
              <img
                // itemprop="image"
                src={getImageUrl(i) || ''}
                width="300"
                height="300"
                alt={item.tag_line}
                style={{ height: 300, width: 300 }}
              />
            ) : (
              <iframe width={300} height={300} src={i} seamless allowFullScreen title={item.slug} />
              // {/* #                <div className="carousel-caption"><h4>video</h4></div># */}
            )}
          </div>
        ))}
      </div>
      <a className="left carousel-control" href={`#${id}`} data-slide="prev">
        ‹
      </a>
      <a className="right carousel-control" href={`#${id}`} data-slide="next">
        ›
      </a>
    </div>
  );
}
