import React, { createContext, useState, useEffect } from 'react';

import { getImage } from '../../utils';

const domKey = 'server-state-fea5e991-eb7d-478e-950a-a03854ff7473';

type NavState = {
  nav_text: string;
  slug: string;
  nav_children: {
    nav_text: string;
    slug: string;
  }[];
}[];

type PostListingState = {
  featured?: Item;
  filtered: Item[];
};

type CatMapState = {
  [slug: string]: {
    slug: string;
    title: string;
  };
};

type SellerMapState = {
  [slug: string]: {
    slug: string;
    name: string;
  };
};

export type AppState = {
  cats: CatMapState;
  sellers: SellerMapState;
  nav: NavState;
  postListing: PostListingState;
  title: string;
};

const defaultState: AppState = {
  cats: {},
  sellers: {},
  nav: [],
  postListing: { filtered: [] },
  title: 'PopOnIt.com (Beta)',
};

type ServerRenderState = {
  isServerRender: boolean;
  state: AppState;
};

export const ServerRenderContext = createContext<ServerRenderState>({
  isServerRender: false,
  state: defaultState,
});

type ServerProps = React.PropsWithChildren<{ state: AppState; rootId: string }>;

const style: { [k: string]: React.CSSProperties } = {
  serverPre: {
    display: 'none',
  },
};

export function Server({ children, state, rootId }: ServerProps) {
  return (
    <>
      <div id={rootId}>
        <ServerRenderContext.Provider value={{ isServerRender: true, state }}>
          {children}
        </ServerRenderContext.Provider>
      </div>
      <pre
        id={domKey}
        style={style.serverPre}
        dangerouslySetInnerHTML={{ __html: JSON.stringify(state) }}
      />
    </>
  );
}

type ClientProps = React.PropsWithChildren<{
  slug?: string;
}>;

export function Client({ children, slug }: ClientProps) {
  if (typeof window === 'undefined') {
    return <>{children}</>;
  }

  const pre = document.getElementById(domKey);
  let domState = defaultState;
  if (pre && pre.innerText) {
    try {
      domState = JSON.parse(pre.innerText);
    } catch (e) {
      console.error('error deserializing dom rendered state', e);
    }
    pre.innerText = '';
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState(domState);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetch(`/api/state/${slug || ''}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(String(res.status));
        }
        return res.json();
      })
      .then((s: AppState) => {
        setState(s);
        console.debug('state refreshed from server');
        document.title = s.title;
        try {
          const ogImageUrl = getImage(s.postListing.featured || {});
          const el = document.querySelector('[property="og:image"]');
          if (ogImageUrl && el) {
            // @ts-ignore
            el.content = ogImageUrl;
          }
        } catch (e) {
          console.error('error setting open graph image', e);
        }
      })
      .catch((err) => {
        console.error('could not refresh state with server', err);
      });
  }, [slug]);

  return (
    <ServerRenderContext.Provider value={{ isServerRender: false, state }}>
      {children}
    </ServerRenderContext.Provider>
  );
}

export default ServerRenderContext;
