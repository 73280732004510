/* part/item_gallery_page.html
<div id="item_gallery_page">
  <div class="row-fluid">
    <div class="span12" id="items">
      {% include "part/item_gallery.html" %}
    </div>
  </div>
  <div class="row-fluid">
    <div class="span12">
      {% include "part/pagination_control.html" %}
    </div>
  </div>
  {% if add_goog_analytics %}
    {% include "part/google-analytics.html" %}
  {% endif %}
  <script>
    function ajax_loader(id, new_content_url){
      $('#' + id).load(new_content_url);
    }

    jQuery(document).ready(function($) {
      // use ajax for pages of the item gallery
      $('.pagination a').each(function() {
        var href = $(this).attr('href');
        $(this).attr('onClick', "ajax_loader('item_gallery_page', '"+href+"')");
        $(this).removeAttr('href');
      });
      $('.pagination .disabled a').each(function() {
        $(this).removeAttr('onClick');
      });
      // loop over all elements creating a tooltip based on their data-tooltip-id attribute
      $('.tooltip-from-element').each(function() {
        var selector = '#' + $(this).data('tooltip-id');
        Tipped.create(this, {ajax: true, closeButton: true, maxWidth: 800, showDelay: 250});
      });
      {% if next_images %}
        // preload images for next page
        $.fn.preload = function() {
          this.each(function(){
              $('<img />').attr('src',this).appendTo('body').hide();
          });
        }
        $({{ next_images|safe }}).preload();
      {% endif %}
    });
  </script>
</div>
*/

import React from 'react';

import ItemGallery from './ItemGallery';
import PaginationControl from './PaginationControl';

type Props = {
  currentPage: number;
  posts: Item[];
  pageCount: number;
  setPage: (p: number) => void;
};

function ItemGalleryPage({ currentPage, pageCount, setPage, posts }: Props) {
  return (
    <div id="item_gallery_page">
      <div className="row-fluid">
        <div className="span12" id="items">
          <ItemGallery posts={posts} />
        </div>
      </div>
      <div className="row-fluid">
        <div className="span12">
          <PaginationControl currentPage={currentPage} setPage={setPage} pageCount={pageCount} />
        </div>
      </div>
    </div>
  );
}

export default ItemGalleryPage;
