/*
{% if "stranger_danger" in alerts %}
   <div class="alert alert-error">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <h4>Stranger Danger</h4>
      Looking for something? ಠ_ಠ
   </div>
{% endif %}
{% if four_oh_four or "404" in alerts %}
  <div class="alert alert-block">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <h4>404!</h4>
    Sorry, couldn't find that url :(
  </div>
{% endif %}
{%  if display_beta_alert or "beta" in alerts %}
  <div class="alert alert-info">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <h4>We're in Beta Testing!</h4>
    Feel free to look around but things will change, break, and (<strong>hopefully</strong>) improve on a weekly basis.
  </div>
{% endif %}
*/

import React from 'react';

type NoticeProps = {
  beta: boolean;
};

function Notices(props: NoticeProps) {
  const { beta } = props;
  return (
    <>
      {beta && (
        <div className="alert alert-info">
          <button type="button" className="close" data-dismiss="alert">
            &times;
          </button>
          <h4>We're in Beta Testing!</h4>
          Feel free to look around but things will change, break, and (<strong>hopefully</strong>)
          improve on a weekly basis.
        </div>
      )}
    </>
  );
}

export default Notices;
