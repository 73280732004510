/* part/pagination_control.html
<div class="pagination pagination-centered">
  <ul>
    <li {% if not posts.has_previous %}class="disabled"{% endif %}><a {% if posts.has_previous %}href="/{% if tag %}{{ tag }}/{% endif %}page{{ posts.previous_page_number }}"{% endif %}>Prev</a></li>
    {% for page_num in posts.paginator.page_range %}
      {% ifequal page_num posts.number  %}
        <li class="active page-link"><a href="/{% if tag %}{{ tag }}/{% endif %}page{{ page_num }}">{{ page_num }}</a></li>
      {% else %}
        <li><a href="/{% if tag %}{{ tag }}/{% endif %}page{{ page_num }}">{{ page_num }}</a></li>
      {% endifequal %}
    {% endfor %}
    <li {% if not posts.has_next %}class="disabled"{% endif %}><a {% if posts.has_next %}href="/{% if tag %}{{ tag }}/{% endif %}page{{ posts.next_page_number }}"{% endif %}>Next</a></li>
  </ul>
</div>
*/

import React from 'react';
import cx from 'classnames';
import range from 'lodash/range';

type PageProps = {
  active?: boolean;
  currentPage: number;
  disabled?: boolean;
  num: number | 'prev' | 'next';
  setPage: (p: number) => void;
};

function Page({ active, disabled, num, setPage, currentPage }: PageProps) {
  const getPageNum = (): number => {
    switch (num) {
      case 'prev':
        return currentPage - 1;
      case 'next':
        return currentPage + 1;
      default:
        return num - 1;
    }
  };
  const onClick = (e: { preventDefault: () => void }) => {
    if (!(disabled || active)) {
      setPage(getPageNum());
    }
    e.preventDefault();
  };
  return (
    <li className={cx('page-link', { disabled: disabled || active, active })} onClick={onClick}>
      <a href={`/page/${getPageNum()}`}>{num}</a>
    </li>
  );
}

type Props = {
  currentPage: number;
  pageCount: number;
  setPage: (p: number) => void;
};

function PaginationControl({ currentPage, pageCount, setPage }: Props) {
  return (
    <div className="pagination pagination-centered">
      <ul>
        <Page
          key="prev"
          disabled={currentPage === 0}
          num="prev"
          currentPage={currentPage}
          setPage={setPage}
        />
        {range(1, pageCount + 1).map((p) => (
          <Page
            key={p}
            num={p}
            currentPage={currentPage}
            setPage={setPage}
            active={currentPage + 1 === p}
          />
        ))}
        <Page
          key="next"
          disabled={currentPage === pageCount - 1}
          num="next"
          currentPage={currentPage}
          setPage={setPage}
        />
      </ul>
    </div>
  );
}

export default PaginationControl;
