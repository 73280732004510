import React, { useEffect } from 'react';

import { Client } from './new/context/serverRenderContext';

type PageProps = {
  path: string;
  firstSlug?: string;
};

function NewApp({ firstSlug }: PageProps) {
  useEffect(() => {
    const oldCss = document.querySelector('link[data-old-app]');
    if (oldCss?.parentElement) {
      oldCss.parentElement.removeChild(oldCss);
      console.debug('removed old css link');
    }
  }, []);
  return (
    <div className="newApp">
      <Client slug={firstSlug} />
    </div>
  );
}

export default NewApp;
