/* part/item_gallery.html
{% for p in posts %}
  <!-- item image and link -->
  <a itemscope itemtype="http://schema.org/Product" class="post_anchor_wrapper tooltip-from-element" data-tooltip-id="tooltip-example-{{ p.id }}"
     href="/{{ p.id }}" data-rank="{{ p.general_rank }}"
     data-tipped="/post_tooltip/{{ p.id }}">
    <img itemprop="image" style="width: 185px; height: 185px;"
         src="{% if p.image %}{{ p.image.url_185x185 }}{% else %}{{ p.image_url }}{% endif %}"
         width="185" height="185" alt="{{ p.tag_line }}"
    />
    <meta itemprop="description" content="{{ p.description }}">
    <meta itemprop="url" content="{{ p.ext_url }}" />
    <meta itemprop="name" content="{{ p.tag_line }}" />
    <span itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <meta itemprop="price" content="{{ p.price }}" />
    </span>
  </a>
{% endfor %}
*/

import React from 'react';
import { Link } from '@reach/router';
import map from 'lodash/map';
import { getImage } from '../utils';

type Props = {
  posts: Item[];
};

const style: { [k: string]: React.CSSProperties } = {
  img: {
    width: 185,
    height: 185,
  },
};

function ItemGallery({ posts }: Props) {
  return (
    <>
      {map(posts, (p) => (
        <Link
          to={`/${p.slug}`}
          key={p.slug}
          id={p.slug}
          className="post_anchor_wrapper"
          onClick={() => {
            try {
              // @ts-ignore
              window.$('html, body').animate({ scrollTop: 0 }, 'slow');
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <img style={style.img} src={getImage(p)} height={185} width={185} alt={p.tag_line} />
        </Link>
      ))}
    </>
  );
}

export default ItemGallery;
