import React from 'react';

import { getImage } from '../../utils';

type Props = {
  item: Item;
  minimal?: boolean;
  include_description?: boolean;
};

const style: { [k: string]: React.CSSProperties } = {
  input: {
    width: '40ch',
    fontFamily: 'monospace',
    marginRight: 10,
  },
  shareContainer: {
    display: 'inline-block',
    position: 'relative',
    top: -10,
  },
  shareIcon: {
    marginLeft: 5,
  },
  shareIconFB: {
    marginLeft: 5,
    borderWidth: 0,
  },
};

function Share(props: Props) {
  const { item, minimal } = props;
  const url = `www.poponit.com/${item.slug}`;
  const encodedUrl = encodeURIComponent(url);
  return (
    <div>
      {minimal ? <h5>Share</h5> : <h4>Share</h4>}
      <div>
        <input
          type="text"
          size={25}
          style={style.input}
          // value="www.poponit.com/{{ item.id }}{% if tag %}/{{ tag }}{% endif %}"
          value={url}
          onChange={() => {}}
        />
        <div style={style.shareContainer}>
          <a
            style={style.shareIconFirst}
            target="_blank"
            rel="noopener noreferrer"
            title="submit to reddit"
            // href="http://www.reddit.com/submit?url=http://www.poponit.com%2F{{ item.id }}{% if tag %}%2F{{ tag|urlencode }}{% endif %}&title={{ item.tag_line|urlencode }}"
            href={`http://www.reddit.com/submit?url=${encodedUrl}`}
          >
            {' '}
            <img
              src="/static/share_icons/reddit.png"
              alt="submit to reddit"
              style={{ borderWidth: 0 }}
            />{' '}
          </a>
          <a
            style={style.shareIcon}
            // href="//pinterest.com/pin/create/button/?url=http%3A%2F%2Fwww.poponit.com%2F{{ item.id }}%2F{% if tag %}{{ tag|urlencode }}{% endif %}&description={{ item.tag_line|urlencode }}&media={{ item.image.url_300x300|urlencode }}"
            href={`http://www.reddit.com/submit?url=${encodedUrl}&description=${encodeURIComponent(
              item.tag_line,
            )}&media=${encodeURIComponent(getImage(item))}`}
            data-pin-do="buttonPin"
            data-pin-config="none"
            title="pin this"
          >
            <img
              src="//assets.pinterest.com/images/pidgets/pin_it_button.png"
              alt="pinterest logo"
            />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            style={style.shareIconFB}
            onClick={() => {
              // "window.open('https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('www.poponit.com/{{ item.id }}{% if tag %}/{{ tag }}{% endif %}'), 'facebook-share-dialog', 'width=626,height=436'); return false;"
              window.open(
                'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl,
                'facebook-share-dialog',
                'width=626,height=436',
              );
            }}
            title="share on Facebook"
          >
            <img src="/static/share_icons/fbshare.gif" alt="share on Facebook" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Share;
