import React from 'react';

type MetaProps = {
  item: Item;
};

function Meta({ item }: MetaProps) {
  return (
    <>
      <meta itemProp="description" content={item.description} />
      <meta itemProp="url" content={item.ext_url} />
      {item.price && (
        <span itemProp="offers" itemScope itemType="http://schema.org/Offer">
          <meta itemProp="price" content={item.price} />
        </span>
      )}
    </>
  );
}

export default Meta;
