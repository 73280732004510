import React, { useEffect, useState } from 'react';

// @ts-ignore
import loadScript from 'load-script';

import Header from './base-page-components/header';
import Notices from './parts/Notice';
import PostListing from './pages/post-listing';
import { Client } from './new/context/serverRenderContext';

type PageProps = {
  path: string;
  firstSlug?: string;
};

function OldApp({ firstSlug }: PageProps) {
  const [loadedBs, setLoadedBs] = useState(false);
  useEffect(() => {
    loadScript('https://code.jquery.com/jquery-1.12.4.min.js', (err: any) => {
      if (err) {
        console.error('error loading jQuery', err);
        return;
      }
      loadScript(
        'https://stackpath.bootstrapcdn.com/twitter-bootstrap/2.3.2/js/bootstrap.min.js',
        (err: any) => {
          if (err) {
            console.error('error loading BS js', err);
          } else {
            setLoadedBs(true);
          }
        },
      );
    });
  }, []);
  return (
    <div className="oldApp">
      <Client slug={firstSlug}>
        <Header firstSlug={firstSlug} />
        <div className="container">
          <Notices beta={!firstSlug} />
          <PostListing loadedBs={loadedBs} />
        </div>
      </Client>
    </div>
  );
}

export default OldApp;
