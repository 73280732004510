import React, { createContext, useEffect, useState } from 'react';

type SRC = boolean;

const defaultContext: SRC = false;

export const ApiConnectionContext = createContext<SRC>(defaultContext);

type ACProps = React.PropsWithChildren<{}>;

export function ApiConnection({ children }: ACProps) {
  const [connection, setConnection] = useState<SRC>(defaultContext);

  useEffect(() => {
    // @ts-ignore web build doesn't find node `Timeout`
    let cancel: number | Timeout;
    const checkApi = () => {
      fetch('/api/status')
        .then((res) => {
          if (!res.ok) {
            throw new Error(`response not okay, status ${res.status}`);
          }
        })
        .then(() => {
          console.debug('connected to api');
          setConnection(true);
          cancel = setTimeout(checkApi, 2 * 60 * 1000);
        })
        .catch(() => {
          setConnection(false);
          console.warn('not connected to api');
          cancel = setTimeout(checkApi, 10 * 1000);
        });
    };
    checkApi();
    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    };
  }, []);

  return (
    <ApiConnectionContext.Provider value={connection}>{children}</ApiConnectionContext.Provider>
  );
}
