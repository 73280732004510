import React from 'react';
import ItemDetails from '../../base-page-components/item-details';

type Props = {
  item: Item;
  loadedBs: boolean;
};

function Featured(props: Props) {
  const { item, loadedBs } = props;
  return (
    <>
      <div className="row-fluid" id="featured-item-row">
        <div className="span12">
          <div className="jumbotron masthead">
            <button
              id="close-button-for-featured-item"
              type="button"
              className="close"
              data-dismiss="hero-unit"
            >
              &times;
            </button>
            <div id="featured_item">
              <ItemDetails loadedBs={loadedBs} item={item} />
            </div>
          </div>
        </div>
      </div>
      <hr id="featured-item-horizontal-row-separator" />
    </>
  );
}

export default Featured;
