import React from 'react';
import { Router } from '@reach/router';

import { ApiConnection } from './new/context/apiConnectionContext';
import NewApp from './NewApp';
import OldApp from './OldApp';

function App() {
  return (
    <ApiConnection>
      <Router>
        <OldApp path="/" />
        <OldApp path=":firstSlug" />
        <NewApp path="/new" />
      </Router>
    </ApiConnection>
  );
}

export default App;
