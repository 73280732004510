import isImage from 'is-image';
import isString from 'lodash/isString';

import { imageDomain, placeholderImage } from './env';

const isFoldered = (img: string) => img.startsWith('photos/');

const removeFolder = (img: string) => img.replace(/^photos\//, '');

const getImageAttr = (obj?: MayHaveImages): string | null => {
  if (!obj) {
    return null;
  }

  const candidates = [obj.image, obj.image_url, ...(obj.tooltipimages || [])];

  let [img] = candidates.filter((i) => i).filter((i) => isString(i) && isImage(i));
  if (typeof img !== 'string') {
    return null;
  }
  if (isFoldered(img)) {
    img = `${imageDomain}${removeFolder(img)}`;
  }
  return img;
};

export const getImageUrl = (partialImg: string | null): string | null => {
  if (!partialImg) {
    return partialImg;
  }
  if (isFoldered(partialImg)) {
    return `${imageDomain}${removeFolder(partialImg)}`;
  }
  if (!(partialImg.includes('://') && isImage(partialImg))) {
    return null;
  }
  return partialImg;
};

export const getImage = (obj?: MayHaveImages): string => {
  const maybe = getImageUrl(getImageAttr(obj));
  return maybe || placeholderImage;
};
