const sites = [
  {
    href: 'http://pinterest.com/poponit/',
    label: 'Pinterest',
  },
  {
    href: 'https://www.facebook.com/pages/Poponit/520144571391976',
    label: 'facebook',
  },
  {
    href: 'http://www.reddit.com/r/poponit',
    label: 'reddit',
  },
  {
    href: 'https://twitter.com/PopOnItDotCom',
    label: 'Twitter',
  },
  {
    href: 'http://www.youtube.com/channel/UCSLhAI7-TszVbXedLe178jw',
    label: 'YouTube',
  },
];

export default sites;
